import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSearchParams } from 'react-router-dom';

interface BotV1 {
  id: string;
  accountId: string;
  templateName: string;
  templateVersion: number;
  baseCoin: string;
  quoteCoin: string;
  botType: string;
  exchangeType: string;
  status: string;
}

interface BotPerformanceV1 {
  templateName: string;
  templateVersion: number;
  nrOfBots: number;
  nrOfTrades: number;
  roi: number;
  roiPerTrade: number;
  profitRatio: number;
  profitLossRatio: number;
}

interface TimeSeriesData {
  timestamp: string;
  roi: number;
  btcChange: number;
}

interface TimeSeriesResponse {
  timeSeries: TimeSeriesData[];
}

interface Account {
  id: string;
  userName: string;
}

interface SelectedTemplate {
  name: string;
  version: number;
}

interface BotOverviewProps {
  accounts: Account[];
  selectedAccountId: string;
  onAccountChange: (accountId: string) => void;
  apiBaseUrl: string;
}

const BotOverview: React.FC<BotOverviewProps> = ({ 
  accounts, 
  selectedAccountId, 
  onAccountChange,
  apiBaseUrl 
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [bots, setBots] = useState<BotV1[]>([]);
  const [performances, setPerformances] = useState<BotPerformanceV1[]>([]);
  const [timeSeriesData, setTimeSeriesData] = useState<TimeSeriesData[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<number>(Number(searchParams.get('period')) || 30);
  const [performanceType, setPerformanceType] = useState<'LIVE' | 'ALL'>((searchParams.get('type') as 'LIVE' | 'ALL') || 'LIVE');
  const [fee, setFee] = useState<number>(Number(searchParams.get('fee')) || 0.2);
  const [isLoadingBots, setIsLoadingBots] = useState(false);
  const [isLoadingPerformances, setIsLoadingPerformances] = useState(false);
  const [isLoadingTimeSeries, setIsLoadingTimeSeries] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate | null>(() => {
    const templateName = searchParams.get('templateName');
    const templateVersion = searchParams.get('templateVersion');
    if (templateName && templateVersion) {
      return {
        name: templateName,
        version: Number(templateVersion)
      };
    }
    return null;
  });

  // Update URL when parameters change
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    params.set('period', selectedPeriod.toString());
    params.set('type', performanceType);
    params.set('fee', fee.toString());
    
    if (selectedTemplate) {
      params.set('templateName', selectedTemplate.name);
      params.set('templateVersion', selectedTemplate.version.toString());
    } else {
      params.delete('templateName');
      params.delete('templateVersion');
    }
    
    setSearchParams(params);
  }, [selectedPeriod, performanceType, fee, selectedTemplate]);

  const selectedAccount = accounts.find(account => account.id === selectedAccountId);
  const selectedUserName = selectedAccount?.userName;

  useEffect(() => {
    if (selectedAccountId) {
      fetchBots();
      fetchPerformances();
      if (selectedUserName) {
        fetchTimeSeriesData();
      }
    }
  }, [selectedAccountId, selectedPeriod, selectedUserName, performanceType, selectedTemplate, fee]);

  const fetchBots = async () => {
    setIsLoadingBots(true);
    try {
      const response = await fetch(`${apiBaseUrl}/bots?accountId=${selectedAccountId}`);
      const data = await response.json();
      setBots(data);
    } catch (error) {
      console.error('Error fetching bots:', error);
    } finally {
      setIsLoadingBots(false);
    }
  };

  const fetchPerformances = async () => {
    setIsLoadingPerformances(true);
    try {
      const response = await fetch(
        `${apiBaseUrl}/bot/performances?accountId=${selectedAccountId}&lastNrOfDays=${selectedPeriod}&fee=${fee}${performanceType === 'LIVE' ? '&botType=LIVE' : ''}`
      );
      const data = await response.json();
      setPerformances(data);
    } catch (error) {
      console.error('Error fetching performances:', error);
    } finally {
      setIsLoadingPerformances(false);
    }
  };

  const fetchTimeSeriesData = async () => {
    if (!selectedUserName) return;
    
    setIsLoadingTimeSeries(true);
    try {
      const response = await fetch(
        `${apiBaseUrl}/bot/trades/timeseries?userName=${selectedUserName}&interval=DAY&periods=${selectedPeriod}&sumTrades=true&fee=${fee}${performanceType === 'LIVE' ? '&filterBotType=LIVE' : ''}${
          selectedTemplate ? `&filterBotTemplate=${selectedTemplate.name}&filterBotVersion=${selectedTemplate.version}` : ''
        }`
      );
      const data: TimeSeriesResponse = await response.json();
      setTimeSeriesData(data.timeSeries || []);
    } catch (error) {
      console.error('Error fetching time series:', error);
      setTimeSeriesData([]);
    } finally {
      setIsLoadingTimeSeries(false);
    }
  };

  const sortedBots = [...bots].sort((a, b) => {
    // First sort by botType (LIVE first)
    if (a.botType === 'LIVE' && b.botType !== 'LIVE') return -1;
    if (a.botType !== 'LIVE' && b.botType === 'LIVE') return 1;
    
    // Then sort by template name
    const templateCompare = a.templateName.localeCompare(b.templateName);
    if (templateCompare !== 0) return templateCompare;
    
    // Then sort by version
    const versionCompare = a.templateVersion - b.templateVersion;
    if (versionCompare !== 0) return versionCompare;
    
    // Finally sort by baseCoin
    return a.baseCoin.localeCompare(b.baseCoin);
  });

  // Filter and sort performances
  const sortedPerformances = [...performances]
    .filter(perf => perf.nrOfTrades > 0)  // Only show bots with trades
    .sort((a, b) => b.nrOfTrades - a.nrOfTrades);

  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'line',
      backgroundColor: '#2d2e32',
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
      }
    },
    title: {
      text: undefined
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          color: '#9CA3AF'
        }
      },
      lineColor: '#374151',
      tickColor: '#374151'
    },
    yAxis: {
      title: {
        text: 'ROI (%)',
        style: {
          color: '#9CA3AF'
        }
      },
      labels: {
        style: {
          color: '#9CA3AF'
        },
        formatter: function() {
          return `${this.value}%`;
        }
      },
      gridLineColor: '#374151'
    },
    series: [{
      name: 'BTC Change',
      type: 'line',
      data: Array.isArray(timeSeriesData) ? timeSeriesData.map(point => ({
        x: new Date(point.timestamp).getTime(),
        y: point.btcChange
      })) : [],
      color: '#f59e0b',
      yAxis: 0
    }, {
      name: 'ROI',
      type: 'line',
      data: Array.isArray(timeSeriesData) ? timeSeriesData.map(point => ({
        x: new Date(point.timestamp).getTime(),
        y: point.roi
      })) : [],
      color: '#3b82f6',
      yAxis: 0
    }],
    legend: {
      itemStyle: {
        color: '#9CA3AF'
      }
    },
    tooltip: {
      backgroundColor: '#2d2e32',
      borderColor: '#374151',
      borderRadius: 8,
      style: {
        color: '#E5E7EB'
      },
      shared: true,
      useHTML: true,
      formatter: function(): string {
        const points = this.points || [];
        if (points.length === 0) return '';

        const x = points[0]?.x;
        if (typeof x !== 'number') return '';

        const date = Highcharts.dateFormat('%Y-%m-%d', x);
        const roi = points.find(p => p.series.name === 'ROI');
        const btcChange = points.find(p => p.series.name === 'BTC Change');

        return `
          <b>${date}</b><br/>
          ${roi?.y != null ? `ROI: ${Highcharts.numberFormat(Number(roi.y), 2)}%<br/>` : ''}
          ${btcChange?.y != null ? `BTC Change: ${Highcharts.numberFormat(Number(btcChange.y), 2)}%` : ''}
        `;
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false
        }
      }
    }
  };

  const LoadingSpinner = () => (
    <div className="flex items-center justify-center h-40">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-accent"></div>
    </div>
  );

  // Generate a consistent color based on template name and version
  const getTemplateColor = (templateName: string, version: number) => {
    const colors = [
      'bg-blue-500', 'bg-purple-500', 'bg-pink-500', 'bg-red-500',
      'bg-orange-500', 'bg-yellow-500', 'bg-green-500', 'bg-teal-500',
      'bg-cyan-500', 'bg-indigo-500'
    ];
    const hash = templateName.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0) + version;
    return colors[hash % colors.length];
  };

  // Get unique template/version combinations and their colors
  const templateVersions = sortedBots
    .reduce((acc: Array<{templateName: string, version: number}>, bot) => {
      const exists = acc.some(item => 
        item.templateName === bot.templateName && 
        item.version === bot.templateVersion
      );
      
      if (!exists) {
        acc.push({
          templateName: bot.templateName,
          version: bot.templateVersion
        });
      }
      
      return acc;
    }, [])
    .map(tv => ({
      ...tv,
      color: getTemplateColor(tv.templateName, tv.version)
    }))
    .sort((a, b) => a.templateName.localeCompare(b.templateName) || a.version - b.version);

  const activeBotCount = sortedBots.filter(bot => bot.botType === 'LIVE').length;
  const totalBotCount = sortedBots.length;

  const handlePerformanceTypeChange = (type: 'LIVE' | 'ALL') => {
    setPerformanceType(type);
  };

  const handleTemplateClick = (templateName: string, version: number) => {
    if (selectedTemplate?.name === templateName && selectedTemplate?.version === version) {
      setSelectedTemplate(null);
    } else {
      setSelectedTemplate({ name: templateName, version: version });
    }
  };

  return (
    <div className="p-2 sm:p-6 bg-dark-300 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
            <select
              value={selectedAccountId}
              onChange={(e) => onAccountChange(e.target.value)}
              className="w-full sm:w-auto bg-dark-200 text-gray-100 border border-dark-100 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-accent"
            >
              <option value="">Select Account</option>
              {accounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.userName}
                </option>
              ))}
            </select>
            <div className="flex items-center space-x-2">
              <span className="text-gray-400">Fee:</span>
              <input
                type="number"
                value={fee}
                onChange={(e) => setFee(Number(e.target.value))}
                step="0.1"
                min="0"
                max="100"
                className="w-20 bg-dark-200 text-gray-100 border border-dark-100 rounded-lg px-2 py-2 focus:outline-none focus:border-blue-accent"
              />
              <span className="text-gray-400">%</span>
            </div>
          </div>

          <div className="grid grid-cols-2 sm:flex sm:space-x-4 gap-2 w-full sm:w-auto">
            {[7, 30, 90, 365].map((period) => (
              <button
                key={period}
                onClick={() => setSelectedPeriod(period)}
                className={`px-3 sm:px-4 py-2 text-sm sm:text-base rounded-lg transition-colors duration-200 ${
                  selectedPeriod === period
                    ? 'bg-blue-accent text-white'
                    : 'bg-dark-200 text-gray-300 border border-dark-100 hover:bg-dark-100'
                }`}
              >
                {period}d
              </button>
            ))}
          </div>
        </div>

        <div className="space-y-4 sm:space-y-8">
          <section>
            <div className="flex flex-col sm:flex-row justify-between items-start gap-4 mb-4">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-100">
                Active Bots ({activeBotCount}/{totalBotCount})
              </h2>
              <div className="w-full sm:w-auto bg-dark-200 rounded-lg border border-dark-100 p-3">
                <div className="text-sm text-gray-400 mb-2">Template Legend:</div>
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2">
                  {templateVersions.map(({ templateName, version, color }) => (
                    <div key={`${templateName}-${version}`} className="flex items-center space-x-2">
                      <div className={`w-3 h-3 sm:w-4 sm:h-4 rounded ${color}`}></div>
                      <span className="text-xs text-gray-300 whitespace-nowrap">
                        {templateName} v{version}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="bg-dark-200 rounded-lg border border-dark-100 p-2 sm:p-4">
              {isLoadingBots ? (
                <LoadingSpinner />
              ) : (
                <div className="max-h-[300px] overflow-y-auto">
                  <div className="grid grid-cols-8 sm:grid-cols-12 md:grid-cols-16 lg:grid-cols-20 xl:grid-cols-24 gap-1">
                    {sortedBots.map((bot) => {
                      const templateColor = getTemplateColor(bot.templateName, bot.templateVersion);
                      const isLive = bot.botType === 'LIVE';
                      return (
                        <div 
                          key={bot.id}
                          className="group relative"
                        >
                          <div 
                            className={`
                              relative w-6 h-6 rounded-md flex items-center justify-center text-[10px] font-medium
                              ${templateColor} 
                              ${!isLive && 'opacity-40'}
                              cursor-pointer transition-all duration-200
                              hover:scale-110 hover:opacity-100
                            `}
                          >
                            {bot.baseCoin.substring(0, 3)}
                            <div className={`
                              absolute -top-1 -right-1 w-2 h-2 rounded-full
                              ${isLive ? 'bg-green-400 animate-pulse' : 'bg-gray-500'}
                              border border-dark-200
                            `}/>
                          </div>
                          
                          {/* Enhanced Tooltip */}
                          <div className="absolute z-10 w-56 p-3 bg-dark-100 rounded-lg shadow-xl
                                        invisible group-hover:visible opacity-0 group-hover:opacity-100
                                        transition-all duration-200 -translate-y-2 group-hover:translate-y-0
                                        left-1/2 -translate-x-1/2 top-full mt-2
                                        border border-dark-200">
                            <div className="text-sm space-y-2">
                              <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-100">{bot.templateName}</span>
                                <span className="text-xs text-gray-400">v{bot.templateVersion}</span>
                              </div>
                              <div className="flex items-center justify-between text-xs">
                                <span className="text-gray-400">Pair:</span>
                                <span className="text-gray-200">{bot.baseCoin}/{bot.quoteCoin}</span>
                              </div>
                              <div className="flex items-center justify-between text-xs">
                                <span className="text-gray-400">Type:</span>
                                <span className={`font-medium ${isLive ? 'text-green-400' : 'text-gray-400'}`}>
                                  {bot.botType}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </section>

          <section>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
              <h2 className="text-xl sm:text-2xl font-bold text-gray-100">
                Performance Overview ({sortedPerformances.length} active)
              </h2>
              <div className="w-full sm:w-auto flex bg-dark-100 rounded-lg p-1">
                <button
                  onClick={() => handlePerformanceTypeChange('LIVE')}
                  className={`flex-1 sm:flex-none px-4 py-2 rounded-lg transition-colors duration-200 ${
                    performanceType === 'LIVE'
                      ? 'bg-blue-accent text-white'
                      : 'text-gray-400 hover:text-gray-300'
                  }`}
                >
                  LIVE
                </button>
                <button
                  onClick={() => handlePerformanceTypeChange('ALL')}
                  className={`flex-1 sm:flex-none px-4 py-2 rounded-lg transition-colors duration-200 ${
                    performanceType === 'ALL'
                      ? 'bg-blue-accent text-white'
                      : 'text-gray-400 hover:text-gray-300'
                  }`}
                >
                  ALL
                </button>
              </div>
            </div>
            <div className="bg-dark-200 rounded-lg border border-dark-100">
              {isLoadingPerformances ? (
                <LoadingSpinner />
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4">
                  {sortedPerformances.map((perf, index) => (
                    <div 
                      key={index} 
                      className={`
                        bg-dark-200 rounded-lg shadow-lg p-4 border 
                        ${selectedTemplate?.name === perf.templateName && selectedTemplate?.version === perf.templateVersion
                          ? 'border-blue-accent'
                          : 'border-dark-100'
                        }
                        hover:border-blue-accent transition-colors duration-200
                        cursor-pointer
                      `}
                      onClick={() => handleTemplateClick(perf.templateName, perf.templateVersion)}
                    >
                      <h3 className="text-lg font-semibold text-gray-100">
                        {perf.templateName} <span className="text-sm text-gray-400">v{perf.templateVersion}</span>
                      </h3>
                      <div className="mt-3 space-y-2">
                        <p className="flex justify-between">
                          <span className="text-gray-400">Trades:</span>
                          <span className="font-medium text-gray-200">{perf.nrOfTrades}</span>
                        </p>
                        <p className="flex justify-between">
                          <span className="text-gray-400">ROI:</span>
                          <span className={`font-medium ${perf.roi >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                            {perf.roi.toFixed(2)}%
                          </span>
                        </p>
                        <p className="flex justify-between">
                          <span className="text-gray-400">ROI per Trade:</span>
                          <span className={`font-medium ${perf.roiPerTrade >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                            {perf.roiPerTrade.toFixed(2)}%
                          </span>
                        </p>
                        <p className="flex justify-between">
                          <span className="text-gray-400">Profit Ratio:</span>
                          <span className="font-medium text-gray-200">{perf.profitRatio.toFixed(2)}%</span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>

          <section>
            <h2 className="text-xl sm:text-2xl font-bold text-gray-100 mb-4">ROI Over Time</h2>
            <div className="bg-dark-200 rounded-lg shadow-lg p-3 sm:p-6 border border-dark-100">
              {isLoadingTimeSeries ? (
                <LoadingSpinner />
              ) : (
                <div className="h-[250px]">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      ...chartOptions,
                      chart: {
                        ...chartOptions.chart,
                        height: 250
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default BotOverview; 