import React, { useEffect, useState } from 'react';
import BotOverview from './components/BotOverview';
import { useSearchParams } from 'react-router-dom';

const API_URLS = {
  production: '',
  sandbox: '/sandbox',
  localhost: '/localhost'
};

type Environment = 'production' | 'sandbox' | 'localhost';

interface Account {
  id: string;
  userName: string;
}

interface AccountsResponse {
  accounts: Account[];
}

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccountId, setSelectedAccountId] = useState<string>(searchParams.get('accountId') || '');
  const [apiBaseUrl, setApiBaseUrl] = useState<string>(API_URLS[searchParams.get('env') as Environment || 'localhost']);
  const [environment, setEnvironment] = useState<Environment>((searchParams.get('env') as Environment) || 'localhost');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAccounts();
  }, [apiBaseUrl]);

  useEffect(() => {
    // Update URL when environment changes
    const params = new URLSearchParams(searchParams);
    params.set('env', environment);
    if (selectedAccountId) {
      params.set('accountId', selectedAccountId);
    }
    setSearchParams(params);
  }, [environment, selectedAccountId]);

  const fetchAccounts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiBaseUrl}/account/all`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: AccountsResponse = await response.json();
      
      if (Array.isArray(data.accounts)) {
        setAccounts(data.accounts);
        if (data.accounts.length > 0 && !selectedAccountId) {
          setSelectedAccountId(data.accounts[0].id);
        }
      } else {
        console.warn('Received invalid accounts data:', data);
        setAccounts([]);
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
      setError(error instanceof Error ? error.message : 'Failed to fetch accounts');
      setAccounts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const cycleEnvironment = () => {
    setSelectedAccountId('');
    setAccounts([]);
    
    const nextEnvironment: { [key in Environment]: Environment } = {
      localhost: 'sandbox',
      sandbox: 'production',
      production: 'localhost'
    };
    
    const next = nextEnvironment[environment];
    setEnvironment(next);
    setApiBaseUrl(API_URLS[next]);
  };

  const getEnvironmentColor = (env: Environment) => {
    switch (env) {
      case 'production':
        return 'bg-red-600';
      case 'sandbox':
        return 'bg-blue-accent';
      case 'localhost':
        return 'bg-green-600';
    }
  };

  return (
    <div className="dark">
      <div className="bg-dark-300 p-4 border-b border-dark-100">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              {error && (
                <span className="text-red-400 text-sm">
                  Error: {error}
                </span>
              )}
              {isLoading && (
                <span className="text-gray-400 text-sm">
                  Loading accounts...
                </span>
              )}
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-gray-400">Environment:</span>
              <button
                onClick={cycleEnvironment}
                className={`px-4 py-2 rounded-lg transition-colors duration-200 text-white ${getEnvironmentColor(environment)}`}
              >
                {environment.charAt(0).toUpperCase() + environment.slice(1)}
              </button>
            </div>
          </div>
        </div>
      </div>
      <BotOverview 
        accounts={accounts}
        selectedAccountId={selectedAccountId}
        onAccountChange={setSelectedAccountId}
        apiBaseUrl={apiBaseUrl}
      />
    </div>
  );
}

export default App;
